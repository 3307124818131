import React, { useCallback, useMemo } from 'react';

const Checkbox = ({ state, onChange }) => {
  const handleChange = useCallback(
    (e) => {
      onChange(e);
    },
    [onChange]
  );

  const statusText = useMemo(() => {
    return state ? 'Aktiviert' : 'Deaktiviert';
  }, [state]);

  const statusClassName = useMemo(() => {
    return state ? 'active' : '';
  }, [state]);

  return (
    <div className="coCookieBannerCheckbox">
      <label className="coCookieBannerCheckbox__switch">
        <input className="coCookieBannerCheckbox__input" type="checkbox" onChange={handleChange} checked={state} />
        <span className="coCookieBannerCheckbox__slider" />
      </label>
      <span className={`coCookieBannerCheckbox__status ${statusClassName}`}>{statusText}</span>
    </div>
  );
};

export default Checkbox;
