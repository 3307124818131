import { useCallback, useMemo, useState } from 'react';

import { isLocalStorageNecessaryCookieAllowed, localStorageAllowAllCookies, localStorageAllowNecessaryCookie } from './localStorageUtils';
import Checkbox from './Checkbox';

const Narrow = ({ closeModal, closeCookieBanner }) => {
  const [necessaryCookiesAllowed, setNecessaryCookiesAllowed] = useState(false);

  const handleNecessaryCookieInputChange = useCallback((e) => {
    const { checked } = e.target;
    setNecessaryCookiesAllowed(checked);
    if (!checked) {
      localStorage.clear();
    } else {
      localStorageAllowNecessaryCookie();
    }
  }, []);

  const additionalNecessaryCookiesText = useMemo(() => {
    if (necessaryCookiesAllowed) return;

    return (
      <p className="coCookieBannerSettingsNarrow_text">
        Wenn du diesen Cookie deaktivierst, können wir die Einstellungen nicht speichern. Dies bedeutet, dass du jedes Mal, wenn du diese Website besuchst, die Cookies erneut aktivieren oder
        deaktivieren musst.
      </p>
    );
  }, [necessaryCookiesAllowed]);

  const handleCookiesAllowed = useCallback(() => {
    closeCookieBanner();
    closeModal();
    localStorageAllowAllCookies();
  }, [closeCookieBanner, closeModal]);

  const handleCookiesPrevented = useCallback(() => {
    closeCookieBanner();
    closeModal();
    localStorage.clear();
  }, [closeCookieBanner, closeModal]);

  const handleCloseButtonClick = useCallback(() => {
    closeModal();
    if (isLocalStorageNecessaryCookieAllowed()) {
      closeCookieBanner();
    }
  }, [closeCookieBanner, closeModal]);

  return (
    <div className="coCookieBannerSettingsNarrow">
      <span className="coCookieBannerSettingsNarrow__closeButton" onClick={handleCloseButtonClick} />
      <div className="coCookieBannerSettingsNarrow__logoContainer">
        <img src="../logo_green.png" alt="logo" width="160" />
      </div>
      <div className="coCookieBannerSettingsNarrow__content">
        <div className="coCookieBannerSettingsNarrow__contentSection">
          <h3 className="coCookieBannerSettingsNarrow__title">Datenschutz-Übersicht</h3>
          <p className="coCookieBannerSettingsNarrow_text">
            Um dieses Angebot aufrecht zu halten, brauchen wir Ihre Zustimmung für die Verwendung von Cookies, weiteren Technologien und Ihren personenbezogenen Daten für die aufgeführten Zwecke, auf
            Basis Ihres Nutzerverhaltens. Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle Funktionen der Seite zur Verfügung stehen.{' '}
            <strong className="coCookieBannerSettingsNarrow__strong">Hinweis zur Datenübermittlung in die USA:</strong> Indem Sie die jeweiligen Zwecke und Anbieter akzeptieren, willigen Sie zugleich
            gem. Art. 49 Abs. 1 S. 1 lit. a) DSGVO ein, dass Ihre Daten möglicherweise in den USA verarbeitet werden. Die USA werden vom Europäischen Gerichtshof als ein Land mit einem nach
            EU-Standards unzureichendem Datenschutzniveau eingeschätzt. Es besteht insbesondere das Risiko, dass Ihre Daten durch US-Behörden, zu Kontroll- und zu Überwachungszwecken, möglicherweise
            auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können.
          </p>
        </div>
        <div className="coCookieBannerSettingsNarrow__contentSection">
          <h3>Unbedingt notwendige Cookies</h3>
          <p className="coCookieBannerSettingsNarrow_text">
            Unbedingt notwendige Cookies sollten jederzeit aktiviert sein, damit wir deine Einstellungen für die Cookie-Einstellungen speichern können.
          </p>
          <Checkbox state={necessaryCookiesAllowed} onChange={handleNecessaryCookieInputChange} />
          {additionalNecessaryCookiesText}
        </div>
      </div>
      <div className="coCookieBannerSettingsNarrow__buttonWrapper">
        <button className="coCookieBannerSettingsNarrow__button" onClick={handleCookiesAllowed}>
          Alle Aktivieren
        </button>
        <button className="coCookieBannerSettingsNarrow__button" onClick={handleCookiesPrevented}>
          Alle Ablehnen
        </button>
      </div>
    </div>
  );
};

export default Narrow;
