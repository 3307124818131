import { useCallback, useEffect, useState } from 'react';
import { isLocalStorageNecessaryCookieSet, localStorageAllowAllCookies } from './localStorageUtils';
import Modal from './Modal';
import Settings from './Settings';

const CookieBanner = () => {
  const [cookieBannerVisible, setCookieBannerVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const closeCookieBanner = useCallback(() => {
    setCookieBannerVisible(false);
  }, []);

  const showCookeBanner = useCallback(() => {
    setCookieBannerVisible(true);
  }, []);

  const handleAllowButtonClick = useCallback(() => {
    closeCookieBanner();
    localStorageAllowAllCookies();
  }, [closeCookieBanner]);

  const handlePreventButtonClick = useCallback(() => {
    closeCookieBanner();
  }, [closeCookieBanner]);

  useEffect(() => {
    if (!isLocalStorageNecessaryCookieSet()) {
      showCookeBanner();
    }
  }, [showCookeBanner]);

  const CookieBannerContent = (
    <>
      <Modal isOpen={modalOpen}>
        <Settings closeModal={closeModal} closeCookieBanner={closeCookieBanner} />
      </Modal>
      <div className="coCookieBanner">
        <p className="coCookieBanner__text">
          Wir verwenden Cookies, um dir die bestmögliche Erfahrung auf unserer Website zu bieten.
          <br />
          In den{' '}
          <button className="coCookieBanner__clickable" tabIndex={0} onClick={openModal}>
            Einstellungen
          </button>{' '}
          kannst du erfahren, welche Cookies wir verwenden oder sie ausschalten.
        </p>
        <div className="coCookieBanner__buttonContainer">
          <button className="coCookieBanner__button" onClick={handleAllowButtonClick}>
            Zustimmen
          </button>
          <button className="coCookieBanner__button" onClick={handlePreventButtonClick}>
            Ablehnen
          </button>
        </div>
      </div>
    </>
  );

  return cookieBannerVisible ? CookieBannerContent : <></>;
};

export default CookieBanner;
