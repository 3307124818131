export const COOKIE_ALLOWED = 'true';
export const COOKIE_PREVENTED = 'false';

const LOCAL_STORAGE_NECESSARY_COOKIE_KEY = 'necessary-cookie';

const getLocalStorageNecessaryCookie = () => {
  return localStorage.getItem(LOCAL_STORAGE_NECESSARY_COOKIE_KEY);
};

export const isLocalStorageNecessaryCookieSet = () => {
  return getLocalStorageNecessaryCookie() !== null;
};

export const localStorageAllowNecessaryCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_NECESSARY_COOKIE_KEY, COOKIE_ALLOWED);
};

export const localStorageAllowAllCookies = () => {
  localStorageAllowNecessaryCookie();
};

export const isLocalStorageNecessaryCookieAllowed = () => {
  return getLocalStorageNecessaryCookie() === COOKIE_ALLOWED;
};
