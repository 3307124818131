import { useCallback, useEffect } from 'react';

const makeBodyOverflowHidden = () => {
  document.body.style.overflow = 'hidden';
};

const makeBodyOverflowAuto = () => {
  document.body.style.overflow = 'auto';
};

const Modal = ({ isOpen, children }) => {
  const preventScroll = useCallback(() => {
    isOpen ? makeBodyOverflowHidden() : makeBodyOverflowAuto();
  }, [isOpen]);

  useEffect(() => {
    preventScroll();

    return () => {
      makeBodyOverflowAuto();
    };
  }, [preventScroll]);

  return <dialog className={`coCookieBannerModal ${isOpen ? 'open' : ''}`}>{isOpen ? children : ''}</dialog>;
};

export default Modal;
