import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { checkIfEmailIsInvalid } from '../Common/helperFunctions/validator';
import { createAxiosInstance } from '../Common/helperFunctions/axios';
import { useSnackbar } from 'notistack';

// import -> scss modules
import LP from './CoLandingPage.module.scss';

// import -> components
import CoFooter from './Append/CoFooter';
import CoFunktion from './Append/CoFunktion';
import CoHeader from './Append/CoHeader';
import CoPaket from './Append/CoPaket';
import { CircularProgress } from '@material-ui/core';

// class -> paket
class PaketPreis {
  constructor(paketname, abo12, abo24, abo36) {
    this.paketname = paketname;
    this.abo12 = abo12;
    this.abo24 = abo24;
    this.abo36 = abo36;
  }

  renderPreis(aboMonths, pakete) {
    const paket = pakete.find((paket) => {
      return paket.dataset.name === this.paketname;
    });

    if (paket) {
      paket.querySelector('[data-type="abo-preis"]').textContent = this[aboMonths] + ' €';
    }
  }
}

// object -> PaketPreis instance
const paket1 = new PaketPreis('paket1', '139,00', '129,00', '119,00');
const paket2 = new PaketPreis('paket2', '169,00', '159,00', '149,00');
const paket3 = new PaketPreis('paket3', '189,00', '179,00', '169,00');

const pakete = [paket1, paket2, paket3];

// react -> component
const CoArrowUp = ({ arrowUpRef }) => {
  return (
    <div className={LP.arrowUp} ref={arrowUpRef}>
      <span>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="angle-up"
          className="svg-inline--fa fa-angle-up fa-w-10"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
          ></path>
        </svg>
      </span>
    </div>
  );
};

// react -> component
const CoSectionHeader = ({ objGlobalState, openKostenlosTestenModalOnClick }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [emailInvalidState, setEmailInvalidState] = useState(false);
  const [pageLoadingState, setPageLoadingState] = useState(false);

  // function ->
  const handleEmailOnChange = (event) => {
    if (!emailInvalidState) {
      return;
    }

    const isValidAfterChange = !checkIfEmailIsInvalid(event.target.value);
    if (isValidAfterChange) {
      setEmailInvalidState(false);
    }
  };

  // function ->
  const submitForm = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const paket = 'Kein Paket ausgewählt';
    const isEmailValid = !checkIfEmailIsInvalid(email);
    if (!isEmailValid) {
      setEmailInvalidState(true);
      return;
    }

    const requestBody = { email, paket };
    setPageLoadingState(true);
    authAxios
      .post('/landing-page/send-mail', requestBody)
      .then(() => {
        enqueueSnackbar('Deine E-Mail wurde erfolgreich gesendet.', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ein Fehler ist aufgetreten.', { variant: 'error' });
      })
      .finally(() => {
        setPageLoadingState(false);
      });
  };

  return (
    <div className={LP.main__header}>
      <div className={LP.main__headerContainer}>
        <div className={LP.main__headerContainerInfo}>
          <h1 className={LP.top}>Mit TracePort die komplette Prozesskette abbilden</h1>
          <div className={LP.middle}>
            <p>Viele weitere Vorteile durch den Einsatz von TracePort</p>
            <ul>
              <li>Logistikprozesse mit mobiler App steuern</li>
              <li>Verfolgung von Lieferungen durch Echtzeit-Livetracking</li>
              <li>Einfache Dokumentation von Schäden durch Fotoarchivierung</li>
              <li>Einfache Anbindung an bestehende ERP-Systeme</li>
              <li>Neue Prozesse können jederzeit erweitert und angepasst werden</li>
              <li>Kontinuierliche Verbesserung und kostenlose Updates</li>
            </ul>
          </div>
          <div className={LP.bottom}>
            <div className={LP.buttons}>
              <div className={LP.kostenlosTestenLink}>
                <button onClick={() => openKostenlosTestenModalOnClick()}>KOSTENLOS TESTEN</button>
              </div>
            </div>
            <div className={LP.formContainer}>
              <form noValidate onSubmit={submitForm}>
                <div className={LP.inputContainer}>
                  <CircularProgress className={`${LP.circularProgress} ${pageLoadingState ? LP['circularProgress--active'] : ''}`} />
                  <input
                    id="kostenlosTesten"
                    type="email"
                    className={emailInvalidState ? LP['kostenlosTestenEmail--invalid'] : ''}
                    size="40"
                    name="email"
                    aria-label="email"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="E-Mail-Adresse eingeben"
                    onChange={handleEmailOnChange}
                  />
                  <span className={`${LP.kostenlosTestenErrorInputMessage} ${emailInvalidState ? LP['kostenlosTestenErrorInputMessage--active'] : ''}`}>
                    <span>Bitte gib eine gültige E-Mail-Adresse ein</span>
                  </span>
                  <div className={LP.kostenlosTestenLink}>
                    <button type="submit">ABSENDEN</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <span className={LP.agb}>
            Mit der Anmeldung akzeptierst du die <Link to="/agb">AGB</Link> und die <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>. Testphase endet nach 14 Tagen automatisch.
          </span>
          <div className={LP.bottomImage}>
            <img width="188" loading="lazy" src="../assets/images/tracporthome/home_main_header_laptop.png" alt="Deine Buchhaltungssoftware" />
          </div>
        </div>
      </div>
    </div>
  );
};

// react -> component
const CoSectionFunktionen = () => {
  return (
    <>
      <CoFunktion
        imgName="bild3.png"
        title="Geräteunabhängig"
        list={['Zugriff über einen Browser (Desktop/Mobile)', 'PWA Funktionen', 'Verwendet Hardware (Kamera) des Mobile-Devices', 'Echtzeitübertragung', 'Betriebssystem unabhängig']}
      />
      <CoFunktion
        imgName="bild2_5_6_7.png"
        title="Kommissionierungsfunktion"
        list={[
          'Einzelnen Artikel können zu Paketen (Gruppen) erstellt werden',
          'Pakete (Gruppen) können zu einzelnen Artikel aufgeteilt werden',
          'Warensendungen übersichtlich gestalten und bearbeiten',
          'Warensendungen zum Versand vorbereiten',
          'Warensendungen in versendet setzen'
        ]}
      />
      <CoFunktion
        imgName="bild4.png"
        title="Erfassung / Artikelverlauf mit Fotofunktion"
        list={['Foto- und Galeriefunktion', 'Fotos mit Zeitstempel', 'Einfache Dokumentation von Schäden durch eine Fotoarchivierung']}
      />
      <CoFunktion
        imgName="bild2_5_6_7.png"
        title="Qualitätskontrolle und Umlagerung"
        list={['Artikel können mit einer Statusabnahme zur Qualitätskontrolle versehen werden', 'Artikel können von Ablagestandorten umgelagert werden']}
      />
      <CoFunktion
        imgName="bild1.png"
        title="Sendungsverfolgung in Echtzeit"
        list={[
          'Einfache Suche und Verwaltung von Artikeln über einen QR-Code oder über die Artikelnummer',
          'Archiv- und Editierfunktion',
          'Verfolgung von Artikel- oder Paketwegen',
          'Ortung überall möglich (im LKW, Schiff, Flugzeug usw.)'
        ]}
      />
      <CoFunktion
        imgName="bild2_5_6_7.png"
        title="Kontrolle des Lieferweges"
        list={[
          'Wo befindet sich die Sendung',
          'Welchen Temperaturen wurde die Sendung ausgesetzt',
          'Kam die Sendung mit Wasser in Berührung',
          'Wann und wo wurde die Sendung geöffnet',
          'Welchen Erschütterungen wurde die Sendung ausgesetzt'
        ]}
      />
      <CoFunktion
        imgName="bild2_5_6_7.png"
        title="Historienfunktionalität / Artikelverlauf"
        list={[
          'Artikel können lückenlos intern und extern verfolgt werden',
          'Statusänderungen von Artikel können auf einem Blick gesehen werden',
          'Artikelstationen werden jeweils mit einem Timestamps angezeigt',
          'Artikel können an jeder Station zusätzlich mit Fotos versehen werden'
        ]}
      />
      <CoFunktion imgName="bild8.png" title="Benutzerverwaltung" list={['Erstellung und Verwaltung von neuen Nutzern', 'Zuweisung von Rollen', 'Individuelle Anpassung von Rechten']} />
      <CoFunktion imgName="bild10.png" title="Sicherheit und Datenschutz" list={['Läuft in der Cloud', 'Serverstandort in Deutschland', 'DSGVO', 'Backups', 'Regelmäßige kostenlose Updates']} />
    </>
  );
};

// react -> component
const CoSectionPreise = ({ paketeRef, aboButtonsRef, openKostenlosTestenModalOnClick }) => {
  return (
    <>
      <div className={LP.main__preiseSectionHeader}>
        <h1>Preise</h1>
      </div>
      <div className={LP.preiseSection__container}>
        <div className={LP.preiseSection__containerBottom}>
          <div className={LP.preiseSection__containerBottomWrapper}>
            <CoPaket
              paketeRef={paketeRef}
              paketDataName="paket1"
              paketName="Paket 1"
              paketFunktion="Lagerverwaltung"
              einmalKosten="999,00"
              kostenSonderAktion="699,00"
              funktionenList={['Tracking', 'Qualitätssicherung', 'Onlineschulung', 'Warenannahme', 'Wareneingang', 'Warenausgang', 'Versand', 'Lagerverwaltung', 'Auftragsverwaltung']}
              preisMonat="19,99"
              buttonLabel="TESTEN"
              isPaketFour={true}
              openKostenlosTestenModalOnClick={openKostenlosTestenModalOnClick}
            />
            <CoPaket
              paketeRef={paketeRef}
              paketDataName="paket2"
              paketFunktion="Live-Tracking"
              paketName="Paket 2"
              einmalKosten="5.555,55"
              kostenSonderAktion="3.333,33"
              funktionenList={[
                'Alle Funktionen aus Paket 1',
                'Live-Tracking Ihrer Transportsendung',
                'GPS-Standortbestimmung',
                'Messung von Erschütterungen',
                'Feuchtigkeitsmessung',
                'Temperaturmessung',
                'Bestimmung Lichteinfall'
              ]}
              preisMonat="1.150,00"
              buttonLabel="TESTEN"
              isPaketFour={true}
              openKostenlosTestenModalOnClick={openKostenlosTestenModalOnClick}
              firstListItemBlue
            />
          </div>
        </div>
        <div className={LP.marker__container}>
          <p className={LP.marker}>*Servicezeiten Mo bis Fr, 9-17 Uhr</p>
        </div>
        <div className={`${LP.paket} ${LP.customPaket}`}>
          <div className={LP.paket__container}>
            <div className={LP.paket__containerTop}>
              <h2>Kundenpaket 3 – Individuelle Zusammenstellung</h2>
              <span>
                <p>Es war kein passendes Paket für Sie dabei?</p>
                <p>Sie möchten die Pakete individuell miteinander verbinden?</p>
                <p>
                  <span style={{ display: 'block' }}>Sprechen Sie uns gerne für ein</span>individuelles Angebot an!
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <p style={{ textAlign: 'center', margin: '40px 0 0' }}>Alle Preise netto, zuzügl. der gesetzlichen MwSt.</p>
    </>
  );
};

// react -> component
const CoSectionKontakt = () => {
  return (
    <>
      <div className={LP.kontakt__header}>
        <h1>Kontakt</h1>
      </div>
      <div className={LP.kontakt__content}>
        <div className={LP.kontakt__contentAdress}>
          <img src="../assets/images/tracporthome/home_main_kontact/location2.png" alt="location-icon" />
          <div className={LP.text}>
            <span>CONVIDIUS business solutions GmbH</span>
            <span>Walsroder Straße 93</span>
            <span>30853 Hannover/Langenhagen</span>
          </div>
        </div>
        <div className={LP.kontakt__contentPerson}>
          <div className={LP.top}>
            <div className={LP.imageContainer}>
              <img src="../assets/images/tracporthome/home_main_kontact/Alexander_Hintermeier.jpeg" width="500" alt="Alexander Hintermeier" />
            </div>
            <div className={LP.text}>
              <h3>Alexander Hintermeier</h3>
              <span>Wir würden uns freuen, Sie als Partner gewinnen zu können.</span>
              <span>Sollten Sie individuelle Wünsche haben, können wir unser Angebot sicher anpassen.</span>
              <span>Sprechen Sie mich gerne an.</span>
            </div>
          </div>
          <div className={LP.bottom}>
            <span>
              Tel:{' '}
              <a target="_blank" href="tel:051187812545">
                0511-878 125 15
              </a>
            </span>
            <span>
              E-Mail:{' '}
              <a target="_blank" href="mailto:a.hintermeier@convidius.de">
                a.hintermeier@convidius.de
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

// react -> component
const CoLandingPage = ({ objGlobalState }) => {
  const [kostenlosTestenModalState, setKostenlosTestenModalState] = useState({
    open: false,
    paket: ''
  });

  const mainRef = useRef();
  const arrowUpRef = useRef();
  const aboButtonsRef = useRef([]);
  const paketeRef = useRef([]);

  // function ->
  const preventScrolling = () => {
    document.documentElement.classList.add('noScroll');
  };

  // function ->
  const handleWindowScroll = () => {
    const { scrollY } = window;
    const mainTop = mainRef.current?.offsetTop;
    if (scrollY >= mainTop) {
      arrowUpRef.current?.classList.add(LP['active']);
    } else {
      arrowUpRef.current?.classList.remove(LP['active']);
    }
  };

  // function ->
  const handleArrowUpClick = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  };

  // function ->
  const openKostenlosTestenModalOnClick = (paket = 'Kein Paket ausgewählt') => {
    setKostenlosTestenModalState({ open: true, paket });
    preventScrolling();
  };

  // function ->
  const handleAboButtonClick = (button) => {
    aboButtonsRef.current?.forEach((_button) => {
      _button?.classList.remove(LP['active']);
    });

    button?.classList.add(LP['active']);

    const aboMonths = button?.dataset.name;
    pakete.forEach((paket) => {
      paket.renderPreis(aboMonths, paketeRef.current);
    });
  };

  // function ->
  const addEventListeners = () => {
    arrowUpRef.current?.addEventListener('click', handleArrowUpClick);
    window.addEventListener('scroll', handleWindowScroll);
    aboButtonsRef.current?.forEach((button) => {
      button?.addEventListener('click', () => {
        handleAboButtonClick(button);
      });
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    addEventListeners();

    return () => (isMounted = false);
  }, []);

  return (
    <div className={LP.coLandingPage}>
      <CoArrowUp arrowUpRef={arrowUpRef} />
      <CoHeader kostenlosTestenModalState={kostenlosTestenModalState} setKostenlosTestenModalState={setKostenlosTestenModalState} objGlobalState={objGlobalState} />
      <main className={LP.main} ref={mainRef}>
        <section className={LP.main__sectionHeader}>
          <CoSectionHeader objGlobalState={objGlobalState} openKostenlosTestenModalOnClick={openKostenlosTestenModalOnClick} />
        </section>
        <section data-type="linked-target" data-name="funktionen" id="funktionen">
          <CoSectionFunktionen />
        </section>
        <section className={LP.main__preiseSection} data-type="linked-target" data-name="preise" id="preise">
          <CoSectionPreise paketeRef={paketeRef} aboButtonsRef={aboButtonsRef} openKostenlosTestenModalOnClick={openKostenlosTestenModalOnClick} />
        </section>
        <section className={LP.main__kontaktSection} data-type="linked-target" data-name="kontakt" id="kontakt">
          <CoSectionKontakt />
        </section>
      </main>
      <CoFooter />
    </div>
  );
};

export default CoLandingPage;
